<template>
  <van-popup v-model="visibility" class="dialog-confirm" :style="style">
    <div class="footer">
      <slot name="footer">
        <img
          class="btn close"
          @click="close"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/petroChina/dialog-close.png"
        />
        <img
          class="btn submit"
          @click="handleSubmit"
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/petroChina/dialog-submit.png"
        />
      </slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "https://cdn.qiumo.net.cn/allsmarketmaterial/petroChina/dialog.png",
    },
  },
  computed: {
    style() {
      return {
        background: `url(${this.background}) 100% top/contain no-repeat`,
      };
    },
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
    handleSubmit() {
      this.$parent.handleSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-confirm {
    box-sizing: border-box;
    width: 720px;
    height: 690px;
    // min-height: 710px;
    // background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/tehui/LTZJ_11.png")
    //   100% top/contain no-repeat;

    .footer {
      padding: 558px 0 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      

      .btn {
        width: 185px;
      }

      .submit {
        padding-left: 18px;
        width: 292px;
      }
    }
  }
}
</style>
